export function getTimeLeft (key: string, timeDuration: number) {
  const startTime = localStorage.getItem(key);
  if (!startTime) return 0;

  const now = Date.now();
  const elapsed = now - parseInt(startTime);
  const timeLeft = timeDuration - elapsed;

  if (timeLeft <= 0) {
    clearLocalStorageData(key);
  }

  return timeLeft;
}

export function clearLocalStorageData(key: string) {
  localStorage.removeItem(key);
}