import { Ribbon } from '../case/CaseFree/Ribbon/Ribbon';
import CentreButton from '../styled/CustomButtons/CentreButton/CentreButton';
import styles from './FirstDepPopup.module.scss';
import Image from 'next/image';
import img1 from '@/assets/images/modalFirstDep/img1.webp';
import img2 from '@/assets/images/modalFirstDep/img2.webp';
import CircularProgressWrapper from '../Pay/PayPromo/CircularProgressTimer/CircularProgressWrapper';
import ellipseMini from '@/assets/images/modalFirstDep/ellipseMini.svg';
import ellipseBig from '@/assets/images/modalFirstDep/ellipseBig.svg';
import ellipseTop from '@/assets/images/modalFirstDep/ellipseTop.svg';
import imgBottom from '@/assets/images/modalFirstDep/imgBottom.webp';
import closeIcon from '@/assets/icons/pay/close.svg';
import { promoStorage } from '@/utils/new-user-promo';
import { useState } from 'react';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useRouter } from 'next/router';
import gift from '@/assets/icons/gift.svg';
import Lightning from '../Icons/Lightning';
import { useMotivationMissions } from '@/utils/contexts/MotivationMissions';

const FirstDepPopup = () => {
  const [open, setOpen] = useState(true);
  const { isAuthorized } = useAuthContext();
  const { isActive, formattedTime, timeLeft } = useMotivationMissions();
  const router = useRouter();

  const progressValue = Math.max(
    5,
    Math.floor(((timeLeft - 10 * 60 * 1000) / (5 * 60 * 1000)) * 100)
  );
  const progressColor =
    progressValue > 75 ? '#D3F85A' : progressValue > 50 ? 'orange' : 'red';

  const handleClick = () => {
    sessionStorage.setItem(
      'local_pm_data',
      JSON.stringify({
        local_pay: 300,
        local_promo: promoStorage.code,
        local_type_currency: 0,
        local_type_pay: 0,
      })
    );

    router.push({
      pathname: router.pathname,
      query: { ...router.query, modal: 'payment' },
    });
  };

  if (!isAuthorized || !isActive) {
    return null;
  }

  return (
    <div className={styles.modal_first_dep}>
      {open ? (
        <div className={styles.modalContent}>
          <div className={styles.ellipseTop}>
            <Image src={ellipseTop} />
          </div>

          <div className={styles.img1}>
            <Image src={img1} />
          </div>
          <div className={styles.img2}>
            <Image src={img2} className={styles.img2} />
          </div>

          <div className={styles.ellipseMini}>
            <Image src={ellipseMini} />
          </div>

          <div className={styles.ellipseBig}>
            <Image src={ellipseBig} />
          </div>

          <div className={styles.textModal}>
            <h2 className={styles.modalTitle}>
              Твой приветственный бонус уже готов!
            </h2>
            <p className={styles.modalSubtitle}>
              Твой промокод на 1й депозит уже в кассе
            </p>
          </div>

          <div className={styles.promoContainer}>
            <div className={styles.badge}>
              <Ribbon text={'+25% к депозиту'} height={24} />
            </div>
            <div className={styles.circularProgress}>
              <div className={styles.wrapper}>
                <div className={styles.bg}></div>
                <CircularProgressWrapper
                  progressValue={progressValue}
                  progressColor={progressColor}
                  timerText={formattedTime}
                />
              </div>
            </div>
            {!formattedTime && (
              <>
                <div className={styles.lightning}>
                  <Lightning color={'#D3F85A'} />
                </div>
                <div className={styles.lightning}>
                  <Lightning color={'#F53FC7'} />
                </div>
                <div className={styles.lightning}>
                  <Lightning color={'#F8995A'} />
                </div>
              </>
            )}

            <div className={styles.promoCode}>
              <p>{promoStorage.code}</p>
            </div>
          </div>
          <div className={styles.imgBottom}>
            <Image src={imgBottom} />
          </div>
          <div className={styles.actionButton}>
            <CentreButton text={'Забрать'} onClick={handleClick} />
          </div>
          <div className={styles.closeButton} onClick={() => setOpen(false)}>
            <Image src={closeIcon} />
          </div>
        </div>
      ) : (
        <div
          className={`${styles.modalContent} ${styles.small}`}
          onClick={handleClick}
        >
          <div className={styles.wrapp}>
            <div className={`${styles.circularProgress} ${styles.small}`}>
              <div className={styles.wrapper}>
                <CircularProgressWrapper
                  progressValue={progressValue}
                  progressColor={progressColor}
                  timerText={formattedTime}
                  small
                />
              </div>
            </div>
            {!formattedTime && (
              <>
                <div className={styles.lightning}>
                  <Lightning color={'#D3F85A'} />
                </div>
                <div className={styles.lightning}>
                  <Lightning color={'#F53FC7'} />
                </div>
                <div className={styles.lightning}>
                  <Lightning color={'#F8995A'} />
                </div>
              </>
            )}

            <div className={styles.giftInfo}>
              <Image src={gift} />
              <p>+25% к депозиту</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstDepPopup;
