import trushIcon from '@/assets/icons/case_filter/trushIcon.svg';
import { CustomTextField } from '@/components/styled/CustomTextField';
import { MpLootboxesFilterModel } from '@/generated/projectlb-hasura';
import { FILTER_MAX_VALUE } from '@/pages/index';
import { track } from '@amplitude/analytics-browser';
import {
  IconButton,
  SelectChangeEvent,
  Slider,
  Typography,
} from '@mui/material';
import { filterPriceAtom } from 'atoms/filter-price-atom';
import { menuAtom } from 'atoms/menu-height-atom';
import { weaponTypesAtom } from 'atoms/weapon-types-atom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import Image from 'next/image';
import { ChangeEvent, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FilterMenuItem, FilterSelect } from '../FilterSelect/FilterSelect';
import styles from './Filters.module.scss';
import posthog from 'posthog-js';

interface FiltersProps {
  initialFilter: MpLootboxesFilterModel;
  categoriesNames: { name: string; anchor: string }[];
}

const Filters = (props: FiltersProps) => {
  const { initialFilter, categoriesNames } = props;
  const { height: scrollOffset } = useAtomValue(menuAtom);

  const weaponTypes = [...initialFilter.filter_weapon_types];

  weaponTypes.unshift({
    name: 'ВСЕ ОРУЖИЯ',
    param: 'all',
  });

  const minPriceValue = initialFilter.min_price;
  const maxPriceValue = initialFilter.max_price;

  function removeAnchorFromURL() {
    const currentURL = window.location.href;
    const newURL = currentURL.split('#')[0]; // Удаление якоря из URL
    window.history.replaceState(null, '', newURL);
  }

  const resetFilters = () => {
    removeAnchorFromURL();
    setPrice([minPriceValue, FILTER_MAX_VALUE]);
    setPriceValue([minPriceValue, FILTER_MAX_VALUE]);
    setSelectedWeapons([weaponTypes[0].param]);
  };

  const setPriceValue = useSetAtom(filterPriceAtom);

  const debouncedCallback = useDebouncedCallback((val, isMin) => {
    track('filter_case', {
      subevent: isMin ? 'price_min' : 'price_max',
      value: isMin ? val[0] : val[1],
    });
    posthog.capture('filter_case', {
      subevent: isMin ? 'price_min' : 'price_max',
      value: isMin ? val[0] : val[1],
    });
    setPriceValue(val);
  }, 300);

  const [price, setPrice] = useState<number[]>([
    minPriceValue,
    FILTER_MAX_VALUE,
  ]);
  const [selectedWeapons, setSelectedWeapons] = useAtom(weaponTypesAtom);

  const onWeaponSelect = (event: SelectChangeEvent<typeof selectedWeapons>) => {
    const {
      target: { value },
    } = event as { target: { value: string[] } };

    if (value.length > 1 && value.includes('all')) {
      const updatedValue = value.filter((item) => item !== 'all');
      track('filter_case', {
        subevent: 'weapon',
        value: updatedValue,
      });
      posthog.capture('filter_case', {
        subevent: 'weapon',
        value: updatedValue,
      });
      setSelectedWeapons(updatedValue);
    } else {
      track('filter_case', {
        subevent: 'weapon',
        value,
      });
      posthog.capture('filter_case', {
        subevent: 'weapon',
        value,
      });
      setSelectedWeapons(value);
    }
    if (value.length === 0) {
      setSelectedWeapons([weaponTypes[0].param]);
    }
  };

  const handleChangePrice = (e: Event, newValue: number | number[]) => {
    const [min] = newValue as number[];
    setPrice(newValue as number[]);
    if (min !== price[0]) {
      debouncedCallback(newValue, true);
    } else {
      debouncedCallback(newValue, false);
    }
  };

  const changePriceFromInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    min: boolean
  ) => {
    if (min) {
      setPrice((old) => {
        const newValue = [Number(event.target.value) || 0, old[1]];
        debouncedCallback(newValue, true);
        return newValue;
      });
    } else {
      setPrice((old) => {
        const newValue = [old[0], Number(event.target.value) || 0];
        debouncedCallback(newValue, false);
        return newValue;
      });
    }
  };

  const onCategoryChange = (event: SelectChangeEvent<unknown>) => {
    track('filter_case', {
      subevent: 'category',
      value: event.target.value,
    });
    posthog.capture('filter_case', {
      subevent: 'category',
      value: event.target.value,
    });
    event.preventDefault();
    const currentUrl = window.location.href;
    const newUrl = `${currentUrl.split('#')[0]}#${event.target.value}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    const targetAnchor = document.querySelector(
      `#${event.target.value}`
    ) as HTMLElement;
    if (targetAnchor) {
      window.scrollTo({
        top: targetAnchor.offsetTop - scrollOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_filters}>
        <FilterSelect
          multiple
          defaultValue={[weaponTypes[0].param]}
          value={selectedWeapons}
          className={styles.container_filters_autocomplete}
          //@ts-ignore
          onChange={onWeaponSelect}
          inputLabel="ТИП ОРУЖИЯ"
        >
          {weaponTypes.map((t, i) => (
            <FilterMenuItem key={t.name} value={t.param} disabled={i === 0}>
              {t.name}
            </FilterMenuItem>
          ))}
        </FilterSelect>

        <FilterSelect
          value=""
          className={styles.container_filters_autocomplete}
          onChange={onCategoryChange}
          inputLabel="КАТЕГОРИЯ КЕЙСОВ"
        >
          {categoriesNames.map((c) => (
            <FilterMenuItem key={c.name} value={c.anchor}>
              {c.name}
            </FilterMenuItem>
          ))}
        </FilterSelect>

        <div className={styles.container_filters_main_price}>
          <Typography className={styles.container_filters_main_price_title}>
            Ценовой диапазон
          </Typography>
          <div className={styles.container_filters_main_price_value}>
            <CustomTextField
              variant="standard"
              onChange={(e) => changePriceFromInput(e, true)}
              InputProps={{
                disableUnderline: true,
              }}
              type="number"
              value={price[0] || ''}
            />

            <CustomTextField
              variant="standard"
              style={{ flex: '1 1 50%' }}
              onChange={(e) => changePriceFromInput(e, false)}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: {
                  textAlign: 'right',
                },
              }}
              type="number"
              value={price[1] || ''}
            />
            {price[1] === FILTER_MAX_VALUE && (
              <Typography className={styles.container_filters_main_price_text}>
                +
              </Typography>
            )}
          </div>
          <div className={styles.container_filters_main_price_slider}>
            <Slider
              size="small"
              step={5}
              min={minPriceValue}
              max={FILTER_MAX_VALUE}
              className={styles.container_filters_main_price_slider_line}
              value={price}
              onChange={handleChangePrice}
            />
          </div>
        </div>
      </div>
      <IconButton className={styles.container_resetBtn} onClick={resetFilters}>
        <Image src={trushIcon} alt="" />
        <Typography className={styles.container_resetBtn_text}>
          Сбросить
        </Typography>
      </IconButton>
    </div>
  );
};

export default Filters;
