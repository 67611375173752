import React from 'react';

export default function Lightning({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M7.48724 0.930299C7.51309 0.809059 7.49817 0.682674 7.44479 0.570788C7.39142 0.458903 7.30257 0.367783 7.19207 0.311593C7.08157 0.255402 6.95561 0.237288 6.83375 0.260066C6.7119 0.282843 6.60098 0.345237 6.51824 0.437549L0.143238 7.56255C0.0708473 7.64348 0.0234301 7.74364 0.00670936 7.85093C-0.0100114 7.95822 0.00467916 8.06806 0.0490078 8.16718C0.0933364 8.26631 0.165408 8.35048 0.256523 8.40955C0.347639 8.46861 0.453902 8.50005 0.562488 8.50005H5.49149L4.51274 13.0698C4.48688 13.191 4.50181 13.3174 4.55518 13.4293C4.60856 13.5412 4.6974 13.6323 4.8079 13.6885C4.9184 13.7447 5.04437 13.7628 5.16622 13.74C5.28808 13.7173 5.399 13.6549 5.48174 13.5625L11.8567 6.43755C11.9291 6.35661 11.9765 6.25646 11.9933 6.14917C12.01 6.04188 11.9953 5.93204 11.951 5.83292C11.9066 5.73379 11.8346 5.64961 11.7435 5.59055C11.6523 5.53148 11.5461 5.50005 11.4375 5.50005H6.50849L7.48724 0.930299Z"
        fill={color || '#D3F85A'}
      />
    </svg>
  );
}
