import { getTimeLeft } from '@/utils/timeFromLocalStorage';

const PROMO_CONFIG = {
  KEY: 'promoStartTime',
  KEY_WELCOME_MISSION: 'promoStartTimeWelcome',
  CODE: 'NEWBIE2025',
  TIMER_DURATION: 15 * 60 * 1000,
  TIME_SHOW_WELCOME_MISSION_MODAL: 30,
};

export const promoStorage = {
  code: PROMO_CONFIG.CODE,

  initNewUserPromo: () => {
    const startTime = Date.now();
    localStorage.setItem(PROMO_CONFIG.KEY, startTime.toString());
  },

  initWelcomeMissionPromo: () => {
    localStorage.setItem(PROMO_CONFIG.KEY_WELCOME_MISSION, String(PROMO_CONFIG.TIME_SHOW_WELCOME_MISSION_MODAL));
  },

  turnOffWelcomeMissionPromo: () => {
    localStorage.removeItem(PROMO_CONFIG.KEY_WELCOME_MISSION);
  },

  setTimeWelcomeMissionPromo: (time = 0) => {
    localStorage.setItem(PROMO_CONFIG.KEY_WELCOME_MISSION, String(time));
  },

  /**
   * Returns the time left until the promo is over
   * @returns the time left in milliseconds, or 0 if the promo is over
   */
  getTimeLeft: () => {
    return getTimeLeft(PROMO_CONFIG.KEY, PROMO_CONFIG.TIMER_DURATION);
  },
  /**
   * Returns the time left until the welcome promo is over
   * @returns the time left in milliseconds, or 0 if the promo is over
   */
  getModalWelcomeMissionTimeLeft: () => {
    return localStorage.getItem(PROMO_CONFIG.KEY_WELCOME_MISSION) || 0;
  },
};
