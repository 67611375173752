import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMissionsCompletionQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetMissionsCompletionQuery = {
  __typename?: 'query_root';
  GetBasicMissionsData?: {
    __typename?: 'BasicMissionsResponse';
    one_time_missions: {
      __typename?: 'BasicOneTimeMissionsList';
      missions_full_count: number;
      missions_complete_count: number;
      bonus_case_available: number;
    };
  } | null;
};

export type GetWelcomeMissionsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetWelcomeMissionsQuery = {
  __typename?: 'query_root';
  Missions__OnetimePageData?: {
    __typename?: 'MissionsOneTimeResponse';
    success: boolean;
    error?: string | null;
    missions_complete_count: number;
    missions_full_count: number;
    bonus_case_available: number;
    missions?: Array<{
      __typename?: 'MissionOneTimeResponse';
      mission_id: string;
      mission_slug: string;
      status: number;
      redirect_type: number;
      redirect_link: string;
      is_disabled: boolean;
    } | null> | null;
    bonus_case_data?: {
      __typename?: 'BonusLootboxContent';
      items?: Array<{
        __typename?: 'BonusLootboxItem';
        item_hash_name: string;
        item_id: string;
        item_image_link: string;
        item_image_link_case?: string | null;
        item_image_link_strip?: string | null;
        item_quality: string;
        item_rarity: string;
        localized_name?: string | null;
      } | null> | null;
      lootbox?: {
        __typename?: 'BaseBonusLootbox';
        lootbox_slug: string;
      } | null;
    } | null;
  } | null;
};

export type GetPermanentMissionsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPermanentMissionsQuery = {
  __typename?: 'query_root';
  GetMissionsData?: {
    __typename?: 'MissionsResponse';
    permanent_missions: {
      __typename?: 'PermanentMissionsList';
      missions_full_count: number;
      missions: Array<{
        __typename?: 'PermanentMission';
        mission_availability: number;
        mission_current_progress: number;
        mission_description: string;
        mission_full_progress: number;
        mission_id: string;
        mission_reward: number;
        mission_title: string;
        mission_image?: string | null;
        mission_timer?: {
          __typename?: 'PermanentMissionTimer';
          launch: boolean;
          timer_value: number;
          max_value: number;
        } | null;
      }>;
    };
  } | null;
};

export type WelcomeMissionProgressMutationVariables = Types.Exact<{
  mission_id?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;

export type WelcomeMissionProgressMutation = {
  __typename?: 'mutation_root';
  Missions__Onetime: {
    __typename?: 'OnetimeMissionResponse';
    success?: boolean | null;
    error?: string | null;
    message?: string | null;
    missions_complete_count: number;
    bonus_case_available: number;
    statuses?: Array<{
      __typename?: 'MissionStatusResponse';
      mission_id: string;
      status?: number | null;
      is_disabled?: boolean | null;
    } | null> | null;
  };
};

export type PermanentMissionProgressMutationVariables = Types.Exact<{
  mission_id?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;

export type PermanentMissionProgressMutation = {
  __typename?: 'mutation_root';
  ProcessUserPermMissionProgress?: {
    __typename?: 'ProcMissionProgressResponse';
    success: boolean;
    error?: string | null;
    message?: string | null;
    redirect_type: number;
    redirect_url?: string | null;
    completed: boolean;
    mark_as_done: boolean;
    current_progress: number;
  } | null;
};


export type GetOpenLastMissionQuery = {
  __typename?: 'query_root';
  Missions__OnetimePageData?: {
    __typename?: 'MissionsOneTimeResponse';
    success: boolean;
    error?: string | null;
    missions_complete_count: number;
    missions_full_count: number;
    bonus_case_available: number;
    missions?: Array<{
      __typename?: 'MissionOneTimeResponse';
      mission_id: string;
      mission_slug: string;
      status: number;
      redirect_type: number;
      redirect_link: string;
      is_disabled: boolean;
    } | null> | null;
    bonus_case_data?: {
      __typename?: 'BonusLootboxContent';
      items?: Array<{
        __typename?: 'BonusLootboxItem';
        item_hash_name: string;
        item_id: string;
        item_image_link: string;
        item_image_link_case?: string | null;
        item_image_link_strip?: string | null;
        item_quality: string;
        item_rarity: string;
        localized_name?: string | null;
      } | null> | null;
      lootbox?: {
        __typename?: 'BaseBonusLootbox';
        lootbox_slug: string;
      } | null;
    } | null;
  } | null;
};

export type GetOpenLastMissionQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export const GetMissionsCompletionDocument = gql`
  query GetMissionsCompletion {
    GetBasicMissionsData {
      one_time_missions {
        missions_full_count
        missions_complete_count
        bonus_case_available
      }
    }
  }
`;

/**
 * __useGetMissionsCompletionQuery__
 *
 * To run a query within a React component, call `useGetMissionsCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionsCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionsCompletionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissionsCompletionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >(GetMissionsCompletionDocument, options);
}
export function useGetMissionsCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >(GetMissionsCompletionDocument, options);
}
export type GetMissionsCompletionQueryHookResult = ReturnType<
  typeof useGetMissionsCompletionQuery
>;
export type GetMissionsCompletionLazyQueryHookResult = ReturnType<
  typeof useGetMissionsCompletionLazyQuery
>;
export type GetMissionsCompletionQueryResult = Apollo.QueryResult<
  GetMissionsCompletionQuery,
  GetMissionsCompletionQueryVariables
>;
export const GetWelcomeMissionsDocument = gql`
  query GetWelcomeMissions {
    Missions__OnetimePageData {
      success
      error
      missions_complete_count
      missions_full_count
      missions {
        mission_id
        mission_slug
        status
        redirect_type
        redirect_link
        is_disabled
      }
      bonus_case_available
      bonus_case_data {
        items {
          item_hash_name
          item_id
          item_image_link
          item_image_link_case
          item_image_link_strip
          item_quality
          item_rarity
          localized_name
        }
        lootbox {
          lootbox_slug
        }
      }
    }
  }
`;

/**
 * __useGetWelcomeMissionsQuery__
 *
 * To run a query within a React component, call `useGetWelcomeMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWelcomeMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWelcomeMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWelcomeMissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWelcomeMissionsQuery,
    GetWelcomeMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWelcomeMissionsQuery,
    GetWelcomeMissionsQueryVariables
  >(GetWelcomeMissionsDocument, options);
}
export function useGetWelcomeMissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWelcomeMissionsQuery,
    GetWelcomeMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWelcomeMissionsQuery,
    GetWelcomeMissionsQueryVariables
  >(GetWelcomeMissionsDocument, options);
}
export type GetWelcomeMissionsQueryHookResult = ReturnType<
  typeof useGetWelcomeMissionsQuery
>;
export type GetWelcomeMissionsLazyQueryHookResult = ReturnType<
  typeof useGetWelcomeMissionsLazyQuery
>;
export type GetWelcomeMissionsQueryResult = Apollo.QueryResult<
  GetWelcomeMissionsQuery,
  GetWelcomeMissionsQueryVariables
>;
export const GetPermanentMissionsDocument = gql`
  query GetPermanentMissions {
    GetMissionsData {
      permanent_missions {
        missions_full_count
        missions {
          mission_availability
          mission_current_progress
          mission_description
          mission_full_progress
          mission_id
          mission_reward
          mission_title
          mission_image
          mission_timer {
            launch
            timer_value
            max_value
          }
        }
      }
    }
  }
`;

/**
 * __useGetPermanentMissionsQuery__
 *
 * To run a query within a React component, call `useGetPermanentMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermanentMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermanentMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermanentMissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >(GetPermanentMissionsDocument, options);
}
export function useGetPermanentMissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >(GetPermanentMissionsDocument, options);
}
export type GetPermanentMissionsQueryHookResult = ReturnType<
  typeof useGetPermanentMissionsQuery
>;
export type GetPermanentMissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermanentMissionsLazyQuery
>;
export type GetPermanentMissionsQueryResult = Apollo.QueryResult<
  GetPermanentMissionsQuery,
  GetPermanentMissionsQueryVariables
>;
export const WelcomeMissionProgressDocument = gql`
  mutation WelcomeMissionProgress($mission_id: uuid = "") {
    Missions__Onetime(mission_id: $mission_id) {
      success
      error
      message
      missions_complete_count
      bonus_case_available
      statuses {
        mission_id
        status
        is_disabled
      }
    }
  }
`;
export type WelcomeMissionProgressMutationFn = Apollo.MutationFunction<
  WelcomeMissionProgressMutation,
  WelcomeMissionProgressMutationVariables
>;

/**
 * __useWelcomeMissionProgressMutation__
 *
 * To run a mutation, you first call `useWelcomeMissionProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWelcomeMissionProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [welcomeMissionProgressMutation, { data, loading, error }] = useWelcomeMissionProgressMutation({
 *   variables: {
 *      mission_id: // value for 'mission_id'
 *   },
 * });
 */
export function useWelcomeMissionProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WelcomeMissionProgressMutation,
    WelcomeMissionProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WelcomeMissionProgressMutation,
    WelcomeMissionProgressMutationVariables
  >(WelcomeMissionProgressDocument, options);
}
export type WelcomeMissionProgressMutationHookResult = ReturnType<
  typeof useWelcomeMissionProgressMutation
>;
export type WelcomeMissionProgressMutationResult =
  Apollo.MutationResult<WelcomeMissionProgressMutation>;
export type WelcomeMissionProgressMutationOptions = Apollo.BaseMutationOptions<
  WelcomeMissionProgressMutation,
  WelcomeMissionProgressMutationVariables
>;
export const PermanentMissionProgressDocument = gql`
  mutation PermanentMissionProgress($mission_id: uuid = "") {
    ProcessUserPermMissionProgress(mission_id: $mission_id) {
      success
      error
      message
      redirect_type
      redirect_url
      completed
      mark_as_done
      current_progress
    }
  }
`;
export type PermanentMissionProgressMutationFn = Apollo.MutationFunction<
  PermanentMissionProgressMutation,
  PermanentMissionProgressMutationVariables
>;

/**
 * __usePermanentMissionProgressMutation__
 *
 * To run a mutation, you first call `usePermanentMissionProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermanentMissionProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permanentMissionProgressMutation, { data, loading, error }] = usePermanentMissionProgressMutation({
 *   variables: {
 *      mission_id: // value for 'mission_id'
 *   },
 * });
 */
export function usePermanentMissionProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PermanentMissionProgressMutation,
    PermanentMissionProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PermanentMissionProgressMutation,
    PermanentMissionProgressMutationVariables
  >(PermanentMissionProgressDocument, options);
}
export type PermanentMissionProgressMutationHookResult = ReturnType<
  typeof usePermanentMissionProgressMutation
>;
export type PermanentMissionProgressMutationResult =
  Apollo.MutationResult<PermanentMissionProgressMutation>;
export type PermanentMissionProgressMutationOptions =
  Apollo.BaseMutationOptions<
    PermanentMissionProgressMutation,
    PermanentMissionProgressMutationVariables
  >;

export type GetOpenLastMissionCaseQuery = {
  __typename?: 'Query';
  Missions__Onetime_LastMissionCase?: {
    __typename?: 'LastMissionCaseResponse';
    success: boolean;
    error?: string | null;
    data?: {
      __typename?: 'LastMissionCaseData';
      avatar_full: string;
      minutes_ago: number;
      persona_name: string;
      user_id: string;
    } | null;
  } | null;
};

export type GetOpenLastMissionCaseQueryVariables = Types.Exact<{
  [key: string]: never;
}>;
/**
 * __useGetWelcomeMissionsQuery__
 *
 * To run a query within a React component, call `useGetWelcomeMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWelcomeMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWelcomeMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenLastMissionCaseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOpenLastMissionCaseQuery,
    GetOpenLastMissionCaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOpenLastMissionCaseQuery,
    GetOpenLastMissionCaseQueryVariables
  >(GetOpenLastMissionCase, options);
}
export function useGetOpenLastMissionCaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOpenLastMissionCaseQuery,
    GetOpenLastMissionCaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOpenLastMissionCaseQuery,
    GetOpenLastMissionCaseQueryVariables
  >(GetOpenLastMissionCase, options);
}
export type GetOpenLastMissionCaseQueryHookResult = ReturnType<
  typeof useGetWelcomeMissionsQuery
>;
export type GetOpenLastMissionCaseLazyQueryHookResult = ReturnType<
  typeof useGetWelcomeMissionsLazyQuery
>;
export type GetOpenLastMissionCaseQueryResult = Apollo.QueryResult<
  GetOpenLastMissionCaseQuery,
  GetOpenLastMissionCaseQueryVariables
>;

export const GetOpenLastMissionCase = gql`
  query GetOpenLastMissionCase {
    Missions__Onetime_LastMissionCase {
      data {
        avatar_full
        minutes_ago
        persona_name
        user_id
      }
      error
      success
    }
  }
`;