import React from 'react';

export default function FireIcons({ color }: { color: string }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_239_1609)">
        <path
          d="M9.6001 28.7999C9.6001 25.6333 10.3501 22.7333 11.8501 20.1C13.3501 17.4666 15.0001 15.1916 16.8001 13.275C18.6001 11.3583 20.2501 9.86662 21.7501 8.79995L24.0001 7.19995V13.15C24.0001 14.15 24.3501 15 25.0501 15.7C25.7501 16.4 26.6168 16.75 27.6501 16.75C28.2168 16.75 28.7251 16.6333 29.1751 16.4C29.6251 16.1666 30.0334 15.8333 30.4001 15.4L31.2001 14.4C33.3001 15.6 35.0334 17.55 36.4001 20.25C37.7668 22.95 38.4334 25.7833 38.4001 28.75C38.3668 31.3499 37.7418 33.7249 36.5251 35.875C35.3084 38.025 33.6001 39.7666 31.4001 41.0999C31.9334 40.3333 32.3334 39.5166 32.6001 38.65C32.8668 37.7833 33.0001 36.9 33.0001 36C33.0001 34.9 32.8084 33.8333 32.4251 32.7999C32.0418 31.7666 31.4668 30.8333 30.7001 30L24.0001 22.5L17.3001 30C16.5334 30.8333 15.9584 31.7666 15.5751 32.7999C15.1918 33.8333 15.0001 34.9 15.0001 36C15.0001 36.9333 15.1418 37.8416 15.4251 38.7249C15.7084 39.6083 16.1334 40.4333 16.7001 41.1999C14.5001 39.8999 12.7668 38.15 11.5001 35.9499C10.2334 33.75 9.6001 31.3666 9.6001 28.7999ZM24.0001 27.9L28.0001 32.4C28.4668 32.9 28.8168 33.4583 29.0501 34.0749C29.2834 34.6916 29.4001 35.3333 29.4001 36C29.4001 37.4999 28.8751 38.775 27.8251 39.8249C26.7751 40.875 25.5001 41.3999 24.0001 41.3999C22.5001 41.3999 21.2251 40.875 20.1751 39.8249C19.1251 38.775 18.6001 37.4999 18.6001 36C18.6001 35.3333 18.7168 34.6916 18.9501 34.0749C19.1834 33.4583 19.5334 32.9 20.0001 32.4L24.0001 27.9Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_239_1609"
          x="-2.3999"
          y="-4.80005"
          width="52.8013"
          height="58.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_239_1609"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_239_1609"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
