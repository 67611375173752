import Link from 'next/link';
import styles from './HeaderPromoBlock.module.scss';
import OptimizedImage from '@/components/Image';

export const HeaderPromoBlock = () => {
  const href = "https://vk.com/hyperdropcom?w=wall-217609264_81960"
  return (
    <Link href={href} passHref>
      <a
        target="_blank"
        href={href}
        rel="noreferrer"
        className={styles.promoBlock}
      ></a>
    </Link>
  );
};
